$base-border-radius: 3px !default;
$base-line-height: 1.5em !default;
$base-spacing: 1.5em !default;
$action-color: $stz-blue;
$dark-gray: #333 !default;
$medium-screen: 40em !default;
$base-font-color: $dark-gray !default;
$sans-serif: "Veranda", sans-serif;
$sans-serif-2: "Veranda", sans-serif;
body {
    color: $base-font-color;
    font-size: $base-font-size;
    line-height: $base-line-height;
    @include clearfix;
    font-family: $sans-serif;
    text-align: left;
}


p {
    margin: 0 0 $small-spacing;
    color: $base-font-color;
    line-height: $base-line-height;
}

/*.ce_text{*/
    /*a::before {*/
        /*content: "›";*/
        /*color: $base-font-color;*/
        /*margin-right: 3px;*/
    /*}*/
/*}*/
#container a {
    padding: 0 5px 2px;
    top: -1px;
    transition: all $base-duration $base-timing;
    border-bottom: 1px solid $light-gray;

    &:active,
    &:focus,
    &:hover {
        background-color: $stz-blue;
    }
}

.ce_text a {
    &:hover {
        background-color: white!important;
        color: $base-font-color;
    }
    color:white;
    background-color: rgba($stz-blue, 0.9);
}

a {
    color: $action-color;
    text-decoration: none;
    transition: color $base-duration $base-timing;

    &:active,
    &:focus,
    &:hover {
        color: tint($action-color, 100%);
    }
}

hr {
    border-bottom: $base-border;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin: $base-spacing 0;
}

.type {
    border-bottom: 1px solid;
    display: inline-block;
    font-family: $sans-serif-2;
    font-size: 0.7em;
    font-weight: 900;
    letter-spacing: 1px;
    margin-bottom: 2em;
    padding: 0.1em 0;
    text-align: left;
    text-transform: uppercase;
}

h1 {
    font-family: $sans-serif-2;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0.6em;
    margin-top: 0.6em;

    @include media($medium-screen) {
        font-size: 2.4em;
    }
}

h2 {
    font-family: $sans-serif;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 1.2em;

    @include media($medium-screen) {
        font-size: 1.4em;
    }
}

code {
    background: #f7f7f7;
    border-radius: $base-border-radius * 1.5;
    border: 1px solid #e0e0e0;
    font-family: monaco;
    font-size: 0.65em;
    font-style: normal;
    padding: 0.1em 0.4em;
    white-space: nowrap;
}

h3 {
    font-family: $sans-serif-2;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

p.date {
    color: transparentize($base-font-color, 0.6);
    font-family: $sans-serif;
    font-size: 0.8em;
    margin-bottom: 0.5em;
}

p {
    font-family: $sans-serif;
    font-weight: 300;
    letter-spacing: 1;
    margin-bottom: 1.5em;

    span {
        font-family: $sans-serif;
        font-size: 0.8em;
        font-weight: 600;
        text-transform: uppercase;
    }
}

hr {
    width: 3em;
}


p.more >a {
    display: inline-block;
    font-family: $sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    margin-left: 0.2em;
    position: relative;
    text-transform: uppercase;

    span {
        font-size: 1.7em;
        position: absolute;
        right: -10px;
        top: -2px;
    }
}

p.author {
    font-family: $sans-serif;
    font-style: italic;
}

