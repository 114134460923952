table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}


td{
	padding:5px;
	border:1px solid grey;
	vertical-align:top;
}
tr:nth-child(2n+1) {
background-color: #f0f0f0;
}
tr:first-child {
	background-color:#003f63;
	color:white!important;
	font-weight:bold;
}
tr:first-child td,
tr:first-child p{
	color:white!important;
	font-weight:bold;
}