.custom{
    @include outer-container;
    #Menubar{
        @include fill-parent();
        #js-mobile-menu{
            padding-left:5px;
        }
        #mainmenu{
            @include span-columns(12);
            li a, li span{
                padding-right:20px;
            }
            .level_2 a, .level_2 span{
                padding-right:30px;
            }
            .level_3 a, .level_3 span{
                padding-right:40px;
            }
            @include media($large-screen) {
                @include span-columns(9);
                li a, li span{
                    padding-right:1em;
                }
                .level_2 a, .level_2 span{
                    padding-right:1em;
                }
                .level_3 a, .level_3 span{
                    padding-right:1em;
                }
            }
        }

        #js-mobile-menu{
            @include span-columns(1);
            @include media($large-screen) {
                display:none;
            }
        }

        #search{
            @include span-columns(12);
            input[type="search"]{
                @include span-columns(8);
            }
            input[type="submit"]{
                @include span-columns(4);
            }

            @include media($large-screen) {
                @include span-columns(3);
                input[type="search"]{
                    @include span-columns(7);
                }
                input[type="submit"]{
                    @include span-columns(5);
                }

            }
        }
    }

}
/*overwrites*/
#Menubar li.submenu > a::after, #Menubar li.submenu > span::after{
    position:relative;
    right:0px;
  }