@import "mainmenu";
@import "breadcrumbs";
@import "language";

#subnavigation{
    li.active span{

        padding: 0 5px 2px;
        top: -1px;
        transition: all $base-duration $base-timing;
        border-bottom: 1px solid $light-gray;
        color: white;
        background-color: $stz-blue;
    }
}

#back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 9999;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    background: #f5f5f5;
    color: white;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    background: $stz-blue;

    &:hover {
        background: white;
        color: $base-font-color;
    }
}
#back-to-top.show {
    opacity: 1;
}
