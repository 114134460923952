.address{
    @include span-columns(12);
    figure{
        text-align: center;
        img{
            width: auto;
            max-height: 300px;
        }
    }
    @include media ($large-screen) {
        figure{
            @include span-columns(2);
            text-align: left;
            img{
                width:100%;
                height:auto;
            }
        }
        p{
            @include span-columns(6);
        }
    }
    p{
        color: lighten($base-font-color,10);
    }
    border-bottom: 1px solid $light-gray;
    padding-bottom: 1em;
    margin-bottom: 1em;
}
