#footer{
    font-size:80%;
    color:#636363;
    margin-top: 4em;

    @include outer-container;
    background-color:$light-gray;
    border-top: 1px solid $border-gray;
    /*@include texture(180deg, $base-font-color, darken($base-font-color,10), 39, 1);*/
    text-align:left;

    #footer_left{

        padding: 0 3em 3em 3em;
        @include span-columns(12);
        @include media ($large-screen) {
            @include span-columns(6);
        }
        #footer_sm{
            i{
                text-align:center;
                width: 40px;
                height: 40px;
                background-color: $stz-blue;
                line-height:40px;
                color:white;
                display: inline-block;
                border-radius: 60px;
                margin: 0 10px;
                &:hover{
                    background-color: shade($action-color, 20%);
                }
            }
            p{
                color:white;
                margin:10px;
            }
        }

        li{
            display: inline-block;
            margin: 5px 0;
            &.first:after{
                color:white;
                content:" ~ "
            }
        }

        a{
            &:hover{
                color: $stz-blue-light;
            }
        }
    }
    #footer_right{
        padding: 0 3em 3em 3em;
        @include span-columns(12);
        @include media ($large-screen) {
            @include span-columns(6);
            @include omega();
        }
        form{
            @include outer-container;
            @include span-columns(12);
            div.name{
                @include span-columns(12);
                @include media ($large-screen) {
                    @include span-columns(6 of 12);
                }
            }
            div.email{
                @include span-columns(12);
                @include media ($large-screen) {
                    @include span-columns(6 of 12);
                    @include omega();
                }
            }
            div.message textarea{
                height:175px;
            }
        }
    }
    #footer_note{
        @include row();
        @include span-columns(12);
        border-top: 1px solid darken($stz-blue,5);
        padding-right: 10px;
        /*background-color:$stz-blue;*/
        @include texture(180deg, $stz-blue, $stz-blue, 39, 1);
        text-align: right;
        padding-bottom: 3px;
        a{
            color:$stz-blue-light;
            font-size: x-small;
            &:hover{
                color: white;
            }
        }
    }

}
