@import "menubar";
@import "footer";
body{
    margin:0px;
}
#container{
    .mod_navigation,
    .mod_article{
        padding-left: 1em;
    }
    @include outer-container;
    /*@include row;*/
    /*margin-top:1em;*/
    ul{
        list-style-type: none;
        li::before {
            content: "›";
            color: $base-font-color;
            margin-right: 5px;
        }
        margin: 1.5em;
        ul{
            margin: 0 1.5em;
        }
    }
    ol{
        margin: 2em;
        list-style-type: decimal;
    }
    #main{
        @include span-columns(12);
        @include media ($large-screen) {
        }
    }
    #main.right{
        @include media ($large-screen) {
            @include span-columns(8);
        }
    }
    #left{
        @include span-columns(12);
        @include media ($large-screen) {
            @include span-columns(4);
        }
    }
}
#gmap{
    padding: 0!important;
}
#main_map{
    h2{
        padding: 0 1em;
    }
    .dlh_googlemap {
        width:100%!important;
    }

}
#container ul li::before{
    margin-right: -5px;
    position: relative;
    left: -10px;
  }