/*ACCORDION*/
.ce_accordionStart:last-of-type {
    margin-bottom:1em;
}
.ce_accordionStart:first-of-type {
    border-top: 1px solid #d7d7d7;
}
.ce_accordionStart {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
}
.ui-accordion-header{
    padding:5px;
    background-color:#f0f0f0;
}
.ui-accordion-header-active,
.ui-accordion-header:hover{
    color:white;
    background-color:#003f63;
    cursor:pointer;
}
.ui-accordion-content{
    padding:5px;
    border-top: 1px solid #d7d7d7;
}