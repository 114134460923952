@mixin grayscale_element($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter:#{"grayscale(#{$value})"};
}

.mod_changelanguage {
    position:relative;
    float: right;
    right: 1em;
    margin-top:15px;
    padding-left:10px;
    z-index:999;
    li{
        display:inline;
        &.active{
            @include grayscale_element(90%);
        }
    }
}
