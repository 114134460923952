.mejs-container iframe,
.mejs-container object,
.mejs-container embed{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mejs-container{
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}
.image_container{
  font-style:italic;
  text-align: center;
}

.image_container.float_left{
  float:left;
  margin-right:10px;
}
.image_container.float_right{
  float:right;
  margin-left:10px;
}

video{
  width:100%;
}
.ce_youtube{
  position:relative;
}
.video-overlay{
  z-index: 33;
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%;
  padding-top: 10em;
  padding-bottom: 10em;
  text-align:center;
}
.video-overlay span{
  line-height:5em;
  background-color: #003f63;
  color:white;
  padding:5px;
}
.video-overlay span:hover{
  cursor:pointer;  
  color: #003f63;
  background-color:white;
}
