time{
    display: block;
    width: 110px;
    border: 1px solid #d7d7d7;
    height: 125px;
    float: left;
    margin-right: 10px;
}
time .month_year{
    display: block;
    text-align: center;
    background-color: #003f63;
    color: white;
}
time .day{
	display: block;
    font-size: 70px;
    text-align: center;
}
.layout_short{
	min-height:150px;
}

.teaser,.more{
	padding-left:110px;
}
.pagination *{
	display:inline-block;
	text-align:center;
}
.pagination li:before{
	display:none;
}
.pagination li span,.pagination li a{
  padding:5px!important;
  min-width:40px;
}
.pagination li .current{
    background-color: #003f63;
    color:white;
}
.pagination:hover{
	color:white;
}