#Menubar {
    $base-border-color: gainsboro !default;
    $base-border-radius: 3px !default;
    $action-color: #477DCA !default;
    /*$large-screen: 10px!default;*/
    $navigation-padding: 1em;
    $navigation-background: $light-gray;
    $navigation-color: transparentize(black, 0.3);
    $navigation-color-hover: white;
    $navigation-height: 60px;
    $navigation-nav-button-background: $action-color;
    $navigation-nav-button-background-hover: lighten($navigation-background, 10%);
    $navigation-nav-button-border: 1px solid lighten($navigation-nav-button-background, 20%);
    $navigation-search-background: lighten($navigation-background, 5);
    $navigation-search-border: 1px solid darken($navigation-background, 5);
    $navigation-active-link-color: transparentize(white, 0.5);
    $navigation-submenu-padding: 1em;
    /*$navigation-submenu-width: 12em;*/
    $navigation-submenu-width: 100%;
    $horizontal-bar-mode: $large-screen;
    /*$horizontal-bar-mode: 768px;*/

    background-color: $navigation-background;
    border-bottom: 1px solid darken($navigation-background, 10);
    min-height: $navigation-height;
    width: 100%;
    z-index: 999;

    .wrapper {
        @include clearfix;
        position: relative;
        z-index: 9999;
    }

    .logo {
        float: left;
        max-height: $navigation-height;
        padding-left: $navigation-padding;
        padding-right: 2em;

        img {
            max-height: $navigation-height;
            padding: 0.8em 0;
        }
    }

    // Mobile view

    .navigation-menu-button {
        color: $navigation-color;
        display: block;
        float: right;
        line-height: $navigation-height;
        margin: 0;
        padding-right: 1em;
        text-decoration: none;
        text-transform: uppercase;
        position: absolute;

        @include media ($horizontal-bar-mode) {
            display: none;
        }

        /*&:focus,*/
        &:hover {
            color: $navigation-color-hover;
        }
    }

    // Nav menu

    nav {
        float: none;
        min-height: $navigation-height;
        z-index: 9999999;

        @include media ($horizontal-bar-mode) {
            float: left;
        }
    }

    ul.level_1 {
        clear: both;
        display: none;
        margin: 0 auto;
        overflow: visible;
        padding: 0;
        width: 100%;
        z-index: 9999;

        &.show {
            display: block;
        }

        @include media ($horizontal-bar-mode) {
            display: inline;
            margin: 0;
            padding: 0;
        }
    }

    // The nav items

    ul li {
        background: $navigation-background;
        display: block;
        line-height: $navigation-height;
        overflow: hidden;
        /*padding-right: 0.8em;*/
        text-align: right;
        width: 100%;
        z-index: 9999;

        @include media ($horizontal-bar-mode) {
            background: transparent;
            display: inline;
            line-height: $navigation-height;
            text-decoration: none;
            width: auto;
            padding-right: 0em;
        }

        a, span {
            color: $navigation-color;
            display: inline-block;
            text-decoration: none;
            width: 100%;

            @include media ($horizontal-bar-mode) {
                padding-right: 1em;
                padding-left: 1em;
                width: auto;
            }

            &:focus,
            &:hover {
                color: $navigation-color-hover;
                background-color:$stz-blue!important;
                transition: background-color 150ms ease;
            }
        }
    }

    .trail >a,
    .active span{
        /*border-bottom: 1px solid $navigation-active-link-color;*/
        /*padding-bottom: 3px;*/
        background-color: $stz-blue!important;
        /*font-weight:bold;*/
        color: $navigation-color-hover;
    }

    // Sub menus

    li.submenu {
        padding-right: 0em;

        /*@include media($horizontal-bar-mode) {*/
        /*padding-right: $navigation-submenu-padding;*/
    /*}*/

        > ul > li:first-child a,
        > ul > li:first-child span  {
            /*padding-top: 1em;*/
        }

        a, span {
            /*margin-right: $navigation-submenu-padding;*/
        }

        > a, span {
            padding-right: 1em;
        }

        > a::after, > span::after {
            /*@include position(absolute, auto -0.4em auto auto);*/
            color: $navigation-color;
            content: "\25BE";
            font-size: 1.2em;
            position: absolute;
            right: 8px;
            &:hover{
                display:none;
            }
        }
        &:focus > .level_2,
        &:hover > .level_2,
        &:focus > .level_3,
        &:hover > .level_3 {
            display: block;
        }

        @include media($horizontal-bar-mode) {
            /*padding-right: 0.8em;*/
            position: relative;
        }
    }

    .level_2 li.submenu{
        overflow: visible;
        padding-right: 0;


        a, span {
            padding-right: 0.8em;
        }

        > a, > span {
            padding-right: 1.6em;
            position: relative;

            /*@include media($horizontal-bar-mode) {*/
            /*margin-right: $navigation-submenu-padding;*/
        /*}*/

            &::after {
                content: "›";
                font-size: 1.2em;
                position: absolute;
                right: $navigation-submenu-padding / 2;
            }
        }

    }

    ul.level_2,
    ul.level_3 {
        display: none;
        padding-left: 0;

        @include media($horizontal-bar-mode) {
            /*left: -$navigation-submenu-padding;*/
            left: 0em;
            position: absolute;
            /*top: 1.5em;*/
        }

        .level_3.fly-out-right {
            @include media($horizontal-bar-mode) {
                left: $navigation-submenu-width;/* - 0.2em;*/
                top: 0;
            }
        }

        .level_3.fly-out-left {
            @include media($horizontal-bar-mode) {
                left: -$navigation-submenu-width;/* + 0.2em;*/
                top: 0;
            }
        }

        .level_3 {
            @include media($horizontal-bar-mode) {
                left: $navigation-submenu-width;/* - 0.2em;*/
                top: 0;
            }
        }

        li {
            display: block;
            padding-right: 0;

            @include media($horizontal-bar-mode) {
                line-height: $navigation-height / 2 - 10px;

                &:first-child > a,
                &:first-child > span {
                    /*border-top-left-radius: $base-border-radius;*/
                    /*border-top-right-radius: $base-border-radius;*/
                }

                &:last-child > a
                &:last-child > span {
                    /*border-bottom-left-radius: $base-border-radius;*/
                    /*border-bottom-right-radius: $base-border-radius;*/
                    padding-bottom: 0.7em;
                }
            }

            a, span {
                background-color: darken($navigation-background, 3%);
                display: inline-block;
                text-align: right;
                width: 100%;
                /*padding-top: 10px;*/
                /*padding-bottom: 10px;*/

                @include media($horizontal-bar-mode) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    background-color: $navigation-background;
                    padding-left: $navigation-submenu-padding;
                    text-align: left;
                    min-width: 160px;
                    width: $navigation-submenu-width;
                }
            }
        }
    }

    // Elements on the far right

    .navigation-tools {
        background: #505050;
        clear: both;
        display: block;
        height: $navigation-height;

        @include media($horizontal-bar-mode) {
            background: transparent;
            clear: none;
            float: right;
        }
    }

    // Search bar

    .search-bar {
        $search-bar-border-color: $base-border-color;
        $search-bar-border: 1px solid $search-bar-border-color;
        $search-bar-background: lighten($search-bar-border-color, 10%);

        float: left;
        padding: 0.85em 0.85em 0.7em 0.6em;
        width: 60%;

        form {
            position: relative;

            input[type=search] {
                background: $navigation-search-background;
                border: $navigation-search-border;
                border-radius: $base-border-radius * 2;
                box-sizing: border-box;
                color: $navigation-color;
                font-size: 0.9em;
                font-style: italic;
                margin: 0;
                padding: 0.5em 0.8em;
                width: 100%;

                @include media($horizontal-bar-mode) {
                    width: 100%;
                }
            }

            button[type=submit] {
                background: $navigation-search-background;
                border: 0;
                bottom: 0.3em;
                left: auto;
                outline: none;
                padding: 0 9px;
                position: absolute;
                right: 0.3em;
                top: 0.3em;

                img {
                    height: 12px;
                    opacity: 0.7;
                    padding: 1px;
                }
            }
        }

        @include media($horizontal-bar-mode) {
            display: inline-block;
            position: relative;
            width: 16em;

            input {
                box-sizing: border-box;
                display: block;
            }
        }
    }
}

#search{
    padding-top: 15px;
    padding-right: 10px;
    font-size: x-small;
}
