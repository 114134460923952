$parallax-window-height: 15em;
$parallax-background-height: $parallax-window-height * 2;

.parallax-window {
    max-height: $parallax-window-height;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
}

.parallax-static-content {
    background: url("images/header_logo_only.png") no-repeat;
    background-position:center;
    background-size: contain;
    @include media ($large-screen) {
        background: url("images/header_full.png") repeat;
        background-position:right;
    }
    height:117px;
    color: #9A9A8A;
    padding: 2em 0;
    position: relative;
    z-index: 9;
    img{
        width: 40%;
        @include texture(180deg, rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5), 39, 0);
        padding: 15px;
    }
}

.parallax-background {
    background-position: top;
    background-size: cover;
    background-color: white;
    height: $parallax-window-height;
    /*height: $parallax-background-height;*/
    left: 0;
    position: absolute;
    top: - $parallax-window-height / 3;
    width: 100%;
}
