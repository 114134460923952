@charset "UTF-8";
/**/
/*$visual-grid: true;*/
/*@import "neat/neat_custom_settings";*/
.invisible {
  display: none; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
  background-color: #003f63;
  border: 0;
  /*border-radius: $base-border-radius;*/
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.8em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, [type='button']:hover, [type='button']:focus, [type='reset']:hover, [type='reset']:focus, [type='submit']:hover, [type='submit']:focus {
    background-color: #00324f;
    color: #fff; }
  button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
      background-color: #003f63; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.8em; }

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea,
select[multiple] {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  /*border-radius: $base-border-radius;*/
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  [type='color']:hover, [type='date']:hover, [type='datetime']:hover, [type='datetime-local']:hover, [type='email']:hover, [type='month']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='time']:hover, [type='url']:hover, [type='week']:hover, input:not([type]):hover, textarea:hover,
  select[multiple]:hover {
    border-color: silver; }
  [type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus,
  select[multiple]:focus {
    border-color: #003f63;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(0, 47, 74, 0.7);
    outline: none; }
  [type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    [type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple]:disabled:hover {
      border: 1px solid #f0f0f0; }

textarea {
  resize: vertical; }

[type="search"] {
  appearance: none; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  max-width: 100%;
  width: auto; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #b4b4b4;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #f0f0f0;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

td {
  padding: 5px;
  border: 1px solid grey;
  vertical-align: top; }

tr:nth-child(2n+1) {
  background-color: #f0f0f0; }

tr:first-child {
  background-color: #003f63;
  color: white !important;
  font-weight: bold; }

tr:first-child td,
tr:first-child p {
  color: white !important;
  font-weight: bold; }

body {
  color: #333;
  font-size: 0.8em;
  line-height: 1.5;
  font-family: "Veranda", sans-serif;
  text-align: left; }
  body::after {
    clear: both;
    content: "";
    display: block; }

p {
  margin: 0 0 0.75em;
  color: #333;
  line-height: 1.5; }

/*.ce_text{*/
/*a::before {*/
/*content: "›";*/
/*color: $base-font-color;*/
/*margin-right: 3px;*/
/*}*/
/*}*/
#container a {
  padding: 0 5px 2px;
  top: -1px;
  transition: all 150ms ease;
  border-bottom: 1px solid #f0f0f0; }
  #container a:active, #container a:focus, #container a:hover {
    background-color: #003f63; }

.ce_text a {
  color: white;
  background-color: rgba(0, 63, 99, 0.9); }
  .ce_text a:hover {
    background-color: white !important;
    color: #333; }

a {
  color: #003f63;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: white; }

hr {
  border-bottom: 1px solid #f0f0f0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

.type {
  border-bottom: 1px solid;
  display: inline-block;
  font-family: "Veranda", sans-serif;
  font-size: 0.7em;
  font-weight: 900;
  letter-spacing: 1px;
  margin-bottom: 2em;
  padding: 0.1em 0;
  text-align: left;
  text-transform: uppercase; }

h1 {
  font-family: "Veranda", sans-serif;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 0.6em;
  margin-top: 0.6em; }
  @media screen and (min-width: 600px) {
    h1 {
      font-size: 2.4em; } }

h2 {
  font-family: "Veranda", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: 1.2em; }
  @media screen and (min-width: 600px) {
    h2 {
      font-size: 1.4em; } }

code {
  background: #f7f7f7;
  border-radius: 4.5px;
  border: 1px solid #e0e0e0;
  font-family: monaco;
  font-size: 0.65em;
  font-style: normal;
  padding: 0.1em 0.4em;
  white-space: nowrap; }

h3 {
  font-family: "Veranda", sans-serif;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  text-transform: uppercase; }

p.date {
  color: rgba(51, 51, 51, 0.4);
  font-family: "Veranda", sans-serif;
  font-size: 0.8em;
  margin-bottom: 0.5em; }

p {
  font-family: "Veranda", sans-serif;
  font-weight: 300;
  letter-spacing: 1;
  margin-bottom: 1.5em; }
  p span {
    font-family: "Veranda", sans-serif;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase; }

hr {
  width: 3em; }

p.more > a {
  display: inline-block;
  font-family: "Veranda", sans-serif;
  font-size: 0.8em;
  font-weight: 700;
  margin-left: 0.2em;
  position: relative;
  text-transform: uppercase; }
  p.more > a span {
    font-size: 1.7em;
    position: absolute;
    right: -10px;
    top: -2px; }

p.author {
  font-family: "Veranda", sans-serif;
  font-style: italic; }

.texture {
  height: 5em;
  width: 100%; }
  .texture .texture-normal {
    background: url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/diagonal-noise.png"), linear-gradient(180deg, #e1f2f1, #bae0de), center no-repeat #e1f2f1 scroll;
    float: left;
    height: 5em;
    width: 50%; }
  .texture .texture-inverted {
    background: url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/inverted/diagonal-noise.png"), linear-gradient(180deg, #e1f2f1, #bae0de), center no-repeat #e1f2f1 scroll;
    float: left;
    height: 5em;
    width: 50%; }

/*CUSTOM*/
#Menubar {
  /*$large-screen: 10px!default;*/
  /*$navigation-submenu-width: 12em;*/
  /*$horizontal-bar-mode: 768px;*/
  background-color: #f0f0f0;
  border-bottom: 1px solid #d7d7d7;
  min-height: 60px;
  width: 100%;
  z-index: 999; }
  #Menubar .wrapper {
    position: relative;
    z-index: 9999; }
    #Menubar .wrapper::after {
      clear: both;
      content: "";
      display: block; }
  #Menubar .logo {
    float: left;
    max-height: 60px;
    padding-left: 1em;
    padding-right: 2em; }
    #Menubar .logo img {
      max-height: 60px;
      padding: 0.8em 0; }
  #Menubar .navigation-menu-button {
    color: rgba(0, 0, 0, 0.7);
    display: block;
    float: right;
    line-height: 60px;
    margin: 0;
    padding-right: 1em;
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    /*&:focus,*/ }
    @media screen and (min-width: 900px) {
      #Menubar .navigation-menu-button {
        display: none; } }
    #Menubar .navigation-menu-button:hover {
      color: white; }
  #Menubar nav {
    float: none;
    min-height: 60px;
    z-index: 9999999; }
    @media screen and (min-width: 900px) {
      #Menubar nav {
        float: left; } }
  #Menubar ul.level_1 {
    clear: both;
    display: none;
    margin: 0 auto;
    overflow: visible;
    padding: 0;
    width: 100%;
    z-index: 9999; }
    #Menubar ul.level_1.show {
      display: block; }
    @media screen and (min-width: 900px) {
      #Menubar ul.level_1 {
        display: inline;
        margin: 0;
        padding: 0; } }
  #Menubar ul li {
    background: #f0f0f0;
    display: block;
    line-height: 60px;
    overflow: hidden;
    /*padding-right: 0.8em;*/
    text-align: right;
    width: 100%;
    z-index: 9999; }
    @media screen and (min-width: 900px) {
      #Menubar ul li {
        background: transparent;
        display: inline;
        line-height: 60px;
        text-decoration: none;
        width: auto;
        padding-right: 0em; } }
    #Menubar ul li a, #Menubar ul li span {
      color: rgba(0, 0, 0, 0.7);
      display: inline-block;
      text-decoration: none;
      width: 100%; }
      @media screen and (min-width: 900px) {
        #Menubar ul li a, #Menubar ul li span {
          padding-right: 1em;
          padding-left: 1em;
          width: auto; } }
      #Menubar ul li a:focus, #Menubar ul li a:hover, #Menubar ul li span:focus, #Menubar ul li span:hover {
        color: white;
        background-color: #003f63 !important;
        transition: background-color 150ms ease; }
  #Menubar .trail > a,
  #Menubar .active span {
    /*border-bottom: 1px solid $navigation-active-link-color;*/
    /*padding-bottom: 3px;*/
    background-color: #003f63 !important;
    /*font-weight:bold;*/
    color: white; }
  #Menubar li.submenu {
    padding-right: 0em;
    /*@include media($horizontal-bar-mode) {*/
    /*padding-right: $navigation-submenu-padding;*/
    /*}*/ }
    #Menubar li.submenu > ul > li:first-child a,
    #Menubar li.submenu > ul > li:first-child span {
      /*padding-top: 1em;*/ }
    #Menubar li.submenu a, #Menubar li.submenu span {
      /*margin-right: $navigation-submenu-padding;*/ }
    #Menubar li.submenu > a, #Menubar li.submenu span {
      padding-right: 1em; }
    #Menubar li.submenu > a::after, #Menubar li.submenu > span::after {
      /*@include position(absolute, auto -0.4em auto auto);*/
      color: rgba(0, 0, 0, 0.7);
      content: "\25BE";
      font-size: 1.2em;
      position: absolute;
      right: 8px; }
      #Menubar li.submenu > a::after:hover, #Menubar li.submenu > span::after:hover {
        display: none; }
    #Menubar li.submenu:focus > .level_2,
    #Menubar li.submenu:hover > .level_2,
    #Menubar li.submenu:focus > .level_3,
    #Menubar li.submenu:hover > .level_3 {
      display: block; }
    @media screen and (min-width: 900px) {
      #Menubar li.submenu {
        /*padding-right: 0.8em;*/
        position: relative; } }
  #Menubar .level_2 li.submenu {
    overflow: visible;
    padding-right: 0; }
    #Menubar .level_2 li.submenu a, #Menubar .level_2 li.submenu span {
      padding-right: 0.8em; }
    #Menubar .level_2 li.submenu > a, #Menubar .level_2 li.submenu > span {
      padding-right: 1.6em;
      position: relative;
      /*@include media($horizontal-bar-mode) {*/
      /*margin-right: $navigation-submenu-padding;*/
      /*}*/ }
      #Menubar .level_2 li.submenu > a::after, #Menubar .level_2 li.submenu > span::after {
        content: "›";
        font-size: 1.2em;
        position: absolute;
        right: 0.5em; }
  #Menubar ul.level_2,
  #Menubar ul.level_3 {
    display: none;
    padding-left: 0; }
    @media screen and (min-width: 900px) {
      #Menubar ul.level_2,
      #Menubar ul.level_3 {
        /*left: -$navigation-submenu-padding;*/
        left: 0em;
        position: absolute;
        /*top: 1.5em;*/ } }
    @media screen and (min-width: 900px) {
      #Menubar ul.level_2 .level_3.fly-out-right,
      #Menubar ul.level_3 .level_3.fly-out-right {
        left: 100%;
        /* - 0.2em;*/
        top: 0; } }
    @media screen and (min-width: 900px) {
      #Menubar ul.level_2 .level_3.fly-out-left,
      #Menubar ul.level_3 .level_3.fly-out-left {
        left: -100%;
        /* + 0.2em;*/
        top: 0; } }
    @media screen and (min-width: 900px) {
      #Menubar ul.level_2 .level_3,
      #Menubar ul.level_3 .level_3 {
        left: 100%;
        /* - 0.2em;*/
        top: 0; } }
    #Menubar ul.level_2 li,
    #Menubar ul.level_3 li {
      display: block;
      padding-right: 0; }
      @media screen and (min-width: 900px) {
        #Menubar ul.level_2 li,
        #Menubar ul.level_3 li {
          line-height: 20px; }
          #Menubar ul.level_2 li:first-child > a,
          #Menubar ul.level_2 li:first-child > span,
          #Menubar ul.level_3 li:first-child > a,
          #Menubar ul.level_3 li:first-child > span {
            /*border-top-left-radius: $base-border-radius;*/
            /*border-top-right-radius: $base-border-radius;*/ }
          #Menubar ul.level_2 li:last-child > a
#Menubar ul.level_2 li:last-child > span,
          #Menubar ul.level_3 li:last-child > a
#Menubar ul.level_2 li:last-child > span, #Menubar ul.level_2 li:last-child > a
          #Menubar ul.level_3 li:last-child > span,
          #Menubar ul.level_3 li:last-child > a
          #Menubar ul.level_3 li:last-child > span {
            /*border-bottom-left-radius: $base-border-radius;*/
            /*border-bottom-right-radius: $base-border-radius;*/
            padding-bottom: 0.7em; } }
      #Menubar ul.level_2 li a, #Menubar ul.level_2 li span,
      #Menubar ul.level_3 li a,
      #Menubar ul.level_3 li span {
        background-color: #e8e8e8;
        display: inline-block;
        text-align: right;
        width: 100%;
        /*padding-top: 10px;*/
        /*padding-bottom: 10px;*/ }
        @media screen and (min-width: 900px) {
          #Menubar ul.level_2 li a, #Menubar ul.level_2 li span,
          #Menubar ul.level_3 li a,
          #Menubar ul.level_3 li span {
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: #f0f0f0;
            padding-left: 1em;
            text-align: left;
            min-width: 160px;
            width: 100%; } }
  #Menubar .navigation-tools {
    background: #505050;
    clear: both;
    display: block;
    height: 60px; }
    @media screen and (min-width: 900px) {
      #Menubar .navigation-tools {
        background: transparent;
        clear: none;
        float: right; } }
  #Menubar .search-bar {
    float: left;
    padding: 0.85em 0.85em 0.7em 0.6em;
    width: 60%; }
    #Menubar .search-bar form {
      position: relative; }
      #Menubar .search-bar form input[type=search] {
        background: #fdfdfd;
        border: 1px solid #e3e3e3;
        border-radius: 6px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.7);
        font-size: 0.9em;
        font-style: italic;
        margin: 0;
        padding: 0.5em 0.8em;
        width: 100%; }
        @media screen and (min-width: 900px) {
          #Menubar .search-bar form input[type=search] {
            width: 100%; } }
      #Menubar .search-bar form button[type=submit] {
        background: #fdfdfd;
        border: 0;
        bottom: 0.3em;
        left: auto;
        outline: none;
        padding: 0 9px;
        position: absolute;
        right: 0.3em;
        top: 0.3em; }
        #Menubar .search-bar form button[type=submit] img {
          height: 12px;
          opacity: 0.7;
          padding: 1px; }
    @media screen and (min-width: 900px) {
      #Menubar .search-bar {
        display: inline-block;
        position: relative;
        width: 16em; }
        #Menubar .search-bar input {
          box-sizing: border-box;
          display: block; } }

#search {
  padding-top: 15px;
  padding-right: 10px;
  font-size: x-small; }

#Breadcrumb {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d7d7d7; }
  #Breadcrumb::after {
    clear: both;
    content: "";
    display: block; }
  #Breadcrumb ul {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%; }
    #Breadcrumb ul:last-child {
      margin-right: 0; }
  #Breadcrumb li {
    display: inline; }
    #Breadcrumb li::after {
      padding: 0 5px;
      content: "›";
      font-size: 1.2em; }
    #Breadcrumb li.last::after {
      content: "";
      display: none; }
  #Breadcrumb li.active {
    padding: 0 5px 2px;
    top: -1px;
    transition: all 150ms ease;
    border-bottom: 1px solid #f0f0f0;
    color: white;
    background-color: #003f63; }

.mod_changelanguage {
  position: relative;
  float: right;
  right: 1em;
  margin-top: 15px;
  padding-left: 10px;
  z-index: 999; }
  .mod_changelanguage li {
    display: inline; }
    .mod_changelanguage li.active {
      -webkit-filter: grayscale(90%);
      -moz-filter: grayscale(90%);
      filter: grayscale(90%); }

#subnavigation li.active span {
  padding: 0 5px 2px;
  top: -1px;
  transition: all 150ms ease;
  border-bottom: 1px solid #f0f0f0;
  color: white;
  background-color: #003f63; }

#back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  background: #f5f5f5;
  color: white;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  background: #003f63; }
  #back-to-top:hover {
    background: white;
    color: #333; }

#back-to-top.show {
  opacity: 1; }

.parallax-window {
  max-height: 15em;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%; }

.parallax-static-content {
  background: url("images/header_logo_only.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: 117px;
  color: #9A9A8A;
  padding: 2em 0;
  position: relative;
  z-index: 9; }
  @media screen and (min-width: 900px) {
    .parallax-static-content {
      background: url("images/header_full.png") repeat;
      background-position: right; } }
  .parallax-static-content img {
    width: 40%;
    background: url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/inverted/subtle_dots.png"), linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), center no-repeat rgba(255, 255, 255, 0.5) scroll;
    padding: 15px; }

.parallax-background {
  background-position: top;
  background-size: cover;
  background-color: white;
  height: 15em;
  /*height: $parallax-background-height;*/
  left: 0;
  position: absolute;
  top: -5em;
  width: 100%; }

.address {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 1em;
  margin-bottom: 1em; }
  .address:last-child {
    margin-right: 0; }
  .address figure {
    text-align: center; }
    .address figure img {
      width: auto;
      max-height: 300px; }
  @media screen and (min-width: 900px) {
    .address figure {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 14.70196%;
      text-align: left; }
      .address figure:last-child {
        margin-right: 0; }
      .address figure img {
        width: 100%;
        height: auto; }
    .address p {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%; }
      .address p:last-child {
        margin-right: 0; } }
  .address p {
    color: #4d4d4d; }

/*ACCORDION*/
.ce_accordionStart:last-of-type {
  margin-bottom: 1em; }

.ce_accordionStart:first-of-type {
  border-top: 1px solid #d7d7d7; }

.ce_accordionStart {
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7; }

.ui-accordion-header {
  padding: 5px;
  background-color: #f0f0f0; }

.ui-accordion-header-active,
.ui-accordion-header:hover {
  color: white;
  background-color: #003f63;
  cursor: pointer; }

.ui-accordion-content {
  padding: 5px;
  border-top: 1px solid #d7d7d7; }

.mejs-container iframe,
.mejs-container object,
.mejs-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.mejs-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  margin-top: 30px;
  margin-bottom: 30px; }

.image_container {
  font-style: italic;
  text-align: center; }

.image_container.float_left {
  float: left;
  margin-right: 10px; }

.image_container.float_right {
  float: right;
  margin-left: 10px; }

video {
  width: 100%; }

.ce_youtube {
  position: relative; }

.video-overlay {
  z-index: 33;
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%;
  padding-top: 10em;
  padding-bottom: 10em;
  text-align: center; }

.video-overlay span {
  line-height: 5em;
  background-color: #003f63;
  color: white;
  padding: 5px; }

.video-overlay span:hover {
  cursor: pointer;
  color: #003f63;
  background-color: white; }

time {
  display: block;
  width: 110px;
  border: 1px solid #d7d7d7;
  height: 125px;
  float: left;
  margin-right: 10px; }

time .month_year {
  display: block;
  text-align: center;
  background-color: #003f63;
  color: white; }

time .day {
  display: block;
  font-size: 70px;
  text-align: center; }

.layout_short {
  min-height: 150px; }

.teaser, .more {
  padding-left: 110px; }

.pagination * {
  display: inline-block;
  text-align: center; }

.pagination li:before {
  display: none; }

.pagination li span, .pagination li a {
  padding: 5px !important;
  min-width: 40px; }

.pagination li .current {
  background-color: #003f63;
  color: white; }

.pagination:hover {
  color: white; }

#uploadform .col_0 {
  display: none; }

.custom {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .custom::after {
    clear: both;
    content: "";
    display: block; }
  .custom #Menubar {
    width: 100%; }
    .custom #Menubar #js-mobile-menu {
      padding-left: 5px; }
    .custom #Menubar #mainmenu {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%; }
      .custom #Menubar #mainmenu:last-child {
        margin-right: 0; }
      .custom #Menubar #mainmenu li a, .custom #Menubar #mainmenu li span {
        padding-right: 20px; }
      .custom #Menubar #mainmenu .level_2 a, .custom #Menubar #mainmenu .level_2 span {
        padding-right: 30px; }
      .custom #Menubar #mainmenu .level_3 a, .custom #Menubar #mainmenu .level_3 span {
        padding-right: 40px; }
      @media screen and (min-width: 900px) {
        .custom #Menubar #mainmenu {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 74.41059%; }
          .custom #Menubar #mainmenu:last-child {
            margin-right: 0; }
          .custom #Menubar #mainmenu li a, .custom #Menubar #mainmenu li span {
            padding-right: 1em; }
          .custom #Menubar #mainmenu .level_2 a, .custom #Menubar #mainmenu .level_2 span {
            padding-right: 1em; }
          .custom #Menubar #mainmenu .level_3 a, .custom #Menubar #mainmenu .level_3 span {
            padding-right: 1em; } }
    .custom #Menubar #js-mobile-menu {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 6.17215%; }
      .custom #Menubar #js-mobile-menu:last-child {
        margin-right: 0; }
      @media screen and (min-width: 900px) {
        .custom #Menubar #js-mobile-menu {
          display: none; } }
    .custom #Menubar #search {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%; }
      .custom #Menubar #search:last-child {
        margin-right: 0; }
      .custom #Menubar #search input[type="search"] {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 65.88078%; }
        .custom #Menubar #search input[type="search"]:last-child {
          margin-right: 0; }
      .custom #Menubar #search input[type="submit"] {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 31.76157%; }
        .custom #Menubar #search input[type="submit"]:last-child {
          margin-right: 0; }
      @media screen and (min-width: 900px) {
        .custom #Menubar #search {
          float: left;
          display: block;
          margin-right: 2.35765%;
          width: 23.23176%; }
          .custom #Menubar #search:last-child {
            margin-right: 0; }
          .custom #Menubar #search input[type="search"] {
            float: left;
            display: block;
            margin-right: 2.35765%;
            width: 57.35098%; }
            .custom #Menubar #search input[type="search"]:last-child {
              margin-right: 0; }
          .custom #Menubar #search input[type="submit"] {
            float: left;
            display: block;
            margin-right: 2.35765%;
            width: 40.29137%; }
            .custom #Menubar #search input[type="submit"]:last-child {
              margin-right: 0; } }

/*overwrites*/
#Menubar li.submenu > a::after, #Menubar li.submenu > span::after {
  position: relative;
  right: 0px; }

#footer {
  font-size: 80%;
  color: #636363;
  margin-top: 4em;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f0f0;
  border-top: 1px solid #d7d7d7;
  /*@include texture(180deg, $base-font-color, darken($base-font-color,10), 39, 1);*/
  text-align: left; }
  #footer::after {
    clear: both;
    content: "";
    display: block; }
  #footer #footer_left {
    padding: 0 3em 3em 3em;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    #footer #footer_left:last-child {
      margin-right: 0; }
    @media screen and (min-width: 900px) {
      #footer #footer_left {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 48.82117%; }
        #footer #footer_left:last-child {
          margin-right: 0; } }
    #footer #footer_left #footer_sm i {
      text-align: center;
      width: 40px;
      height: 40px;
      background-color: #003f63;
      line-height: 40px;
      color: white;
      display: inline-block;
      border-radius: 60px;
      margin: 0 10px; }
      #footer #footer_left #footer_sm i:hover {
        background-color: #00324f; }
    #footer #footer_left #footer_sm p {
      color: white;
      margin: 10px; }
    #footer #footer_left li {
      display: inline-block;
      margin: 5px 0; }
      #footer #footer_left li.first:after {
        color: white;
        content: " ~ "; }
    #footer #footer_left a:hover {
      color: #8ba8bd; }
  #footer #footer_right {
    padding: 0 3em 3em 3em;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    #footer #footer_right:last-child {
      margin-right: 0; }
    @media screen and (min-width: 900px) {
      #footer #footer_right {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 48.82117%;
        margin-right: 0; }
        #footer #footer_right:last-child {
          margin-right: 0; } }
    #footer #footer_right form {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%; }
      #footer #footer_right form::after {
        clear: both;
        content: "";
        display: block; }
      #footer #footer_right form:last-child {
        margin-right: 0; }
      #footer #footer_right form div.name {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%; }
        #footer #footer_right form div.name:last-child {
          margin-right: 0; }
        @media screen and (min-width: 900px) {
          #footer #footer_right form div.name {
            float: left;
            display: block;
            margin-right: 2.35765%;
            width: 48.82117%; }
            #footer #footer_right form div.name:last-child {
              margin-right: 0; } }
      #footer #footer_right form div.email {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%; }
        #footer #footer_right form div.email:last-child {
          margin-right: 0; }
        @media screen and (min-width: 900px) {
          #footer #footer_right form div.email {
            float: left;
            display: block;
            margin-right: 2.35765%;
            width: 48.82117%;
            margin-right: 0; }
            #footer #footer_right form div.email:last-child {
              margin-right: 0; } }
      #footer #footer_right form div.message textarea {
        height: 175px; }
  #footer #footer_note {
    display: block;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    border-top: 1px solid #002f4a;
    padding-right: 10px;
    /*background-color:$stz-blue;*/
    background: url("https://raw.githubusercontent.com/thoughtbot/refills/master/source/images/textures/subtle_dots.png"), linear-gradient(180deg, #003f63, #003f63), center no-repeat #003f63 scroll;
    text-align: right;
    padding-bottom: 3px; }
    #footer #footer_note::after {
      clear: both;
      content: "";
      display: block; }
    #footer #footer_note:last-child {
      margin-right: 0; }
    #footer #footer_note a {
      color: #8ba8bd;
      font-size: x-small; }
      #footer #footer_note a:hover {
        color: white; }

body {
  margin: 0px; }

#container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /*@include row;*/
  /*margin-top:1em;*/ }
  #container .mod_navigation,
  #container .mod_article {
    padding-left: 1em; }
  #container::after {
    clear: both;
    content: "";
    display: block; }
  #container ul {
    list-style-type: none;
    margin: 1.5em; }
    #container ul li::before {
      content: "›";
      color: #333;
      margin-right: 5px; }
    #container ul ul {
      margin: 0 1.5em; }
  #container ol {
    margin: 2em;
    list-style-type: decimal; }
  #container #main {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    #container #main:last-child {
      margin-right: 0; }
  @media screen and (min-width: 900px) {
    #container #main.right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 65.88078%; }
      #container #main.right:last-child {
        margin-right: 0; } }
  #container #left {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    #container #left:last-child {
      margin-right: 0; }
    @media screen and (min-width: 900px) {
      #container #left {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 31.76157%; }
        #container #left:last-child {
          margin-right: 0; } }

#gmap {
  padding: 0 !important; }

#main_map h2 {
  padding: 0 1em; }

#main_map .dlh_googlemap {
  width: 100% !important; }

#container ul li::before {
  margin-right: -5px;
  position: relative;
  left: -10px; }

.mod_newslist .info, .mod_newslist h2 {
  display: inline; }
  .mod_newslist .info a, .mod_newslist h2 a {
    padding: 0 !important;
    color: #333; }
  .mod_newslist .info a:hover, .mod_newslist h2 a:hover {
    color: #d7d7d7; }

.mod_newslist p {
  margin-bottom: 0; }

.mod_newslist .layout_short {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 2em;
  padding-bottom: 2em; }

/*@import "content/content";*/
