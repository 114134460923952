.mod_newslist{
    .info, h2{
        display:inline;
        a{
            padding:0!important;
            color: #333;
        }
        a:hover{
            color: #d7d7d7;
        }
    }
    p{
        margin-bottom: 0;
    }
    .layout_short{
        border-bottom: 1px solid $light-gray;
        margin-bottom: 2em;
        padding-bottom: 2em;
    }
}
