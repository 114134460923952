#Breadcrumb{
    @include outer-container;
    ul{
        @include span-columns(10);
    }
    padding:10px;
    background-color: $light-gray;
    border-bottom: 1px solid darken($light-gray, 10);
    li{
        display:inline;
        &::after{
            padding: 0 5px;
            content: "›";
            font-size: 1.2em;
        }
        &.last::after{
            content: "";
            display:none;
        }
    }
    li.active {

        padding: 0 5px 2px;
        top: -1px;
        transition: all $base-duration $base-timing;
        border-bottom: 1px solid $light-gray;
        color: white;
        background-color: $stz-blue;
    }
}
